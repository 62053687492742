export function addQueryParameter(url: URL, name: string, value: null | undefined | string | number | boolean | number[] | string[] | boolean[]) {
    if (value == null) return;
    if (Array.isArray(value)) {
        for (let o of value) {
            url.searchParams.append(name, o?.toString());
        }
    } else {
        url.searchParams.append(name, value.toString());
    }
}

function jsonHasMessage<T>(obj: T): obj is T & { message: string } {
    return "message" in obj
}

export async function fetchAndParse<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    const response = await fetch(input, init).catch(err => console.log(err));
    let json: T | null = null;
    const text = response !== undefined ? await response.text() : 'error fetching';
    try {
        json = JSON.parse(text);
    } catch { }
    if (response!.status >= 300) {
        const message = json !== null ? jsonHasMessage(json) ? json.message : text : text;
        throw {
            url: input,
            body: init?.body,
            status: response!.status,
            statusText: response!.statusText,
            message: message,
            json,
            toLog() {
                return {
                    url: this.url,
                    body: this.body,
                    json: this.json,
                    status: this.status,
                    statusText: this.statusText
                };
            },
            toString() {
                return `${this.status} - ${this.message || this.statusText}`;
            }
        };
    }
    return json as T;
}

export function getCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export function eraseCookie(name: string) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}