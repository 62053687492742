import './header.scss'
import logo from '../assets/Logo-RLS.png';

export function Header() {
    return <div className="banner">
        <div className='inner-container'>
            <div className={'company'}>
                <img style={{ cursor: 'pointer' }} src={logo} alt='logo' />
            </div>
        </div>
    </div>
}
