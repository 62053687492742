import Button from '@mui/material/Button/Button';
import React, { useEffect, useState } from 'react';
import './App.scss';
import { Header } from './components/header';
import { getApiMyInfo } from './services/user';
import { AuthResponse } from "./models/AuthResponse";
import { getCookie } from './services/utils';
import { headers } from './services/config';

function AccountItem(p: { name: string, value: string | number | boolean | undefined, color?: string }) {
    return <div className='settings-row'>
        <div className='left-column'>{p.name}:</div><div style={p.color ? { color: p.color } : {}} className="left-value">{typeof p.value === "boolean" ? p.value.toString() : p.value}</div>
    </div>
}

function AccountItemButton(p: { name: string, onClick: () => void, show: boolean }) {

    return <div className='settings-row'>
        <div className='left-column'>{p.name}:</div><Button style={{ marginLeft: 10 }} size="small" variant="outlined" onClick={p.onClick}>{p.show ? "HIDE" : "SHOW"} {p.name}</Button>
    </div>
}

function AccountSection(p: { children: JSX.Element[], title: string }) {

    return <div className='widget'>
        <span className='widget-title'>{p.title}</span>
        {p.children}
    </div>
}

function App() {
    const [showTenant, setShowTenant] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [userInfo, setUserInfo] = useState<AuthResponse>();

    useEffect(() => {
        const abortController = new AbortController();
        const session = getCookie("session");
        const tenantSchema = getCookie("tenant");
        if (session && tenantSchema) {
            headers['Authorization'] = `Bearer ${session}`;
            headers['X-Tenant'] = tenantSchema || "";
        } else {
            delete headers['Authorization'];
        }
        getApiMyInfo(abortController.signal).then((res) => setUserInfo(res));
    }, [])

    return (<div>
        <Header />

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
            <AccountSection title="Account Info">
                <AccountItem name="Id" value={userInfo?.user?.id} />
                <AccountItem name="Auth Type" value={userInfo?.user?.authType} />
                <AccountItem name="Name" value={userInfo?.user?.name} />
                <AccountItem name="Email" value={userInfo?.user?.email} />
                <AccountItem name="Role" value={userInfo?.user?.role} />
                <AccountItem name="Cognito Id" value={userInfo?.cognito?.cognitoId} />
                <AccountItem name="Cognito Pool Id" value={userInfo?.cognito?.cognitoPoolId} />
                <AccountItemButton show={showTenant} name="Tenant" onClick={() => setShowTenant(!showTenant)} />
            </AccountSection>
            {showTenant && <AccountSection title="">
                <AccountItem name="Client Id" value={userInfo?.tenant?.clientId} />
                <AccountItem name="Pool Id" value={userInfo?.tenant?.poolId} />
                <AccountItem name="Schema Id" value={userInfo?.tenant?.schema} />
                <AccountItem name="Name" value={userInfo?.tenant?.name} />
                <AccountItemButton show={showUser} name="User" onClick={() => setShowUser(!showUser)} />
            </AccountSection>
            }
            {showUser && showTenant &&
                <AccountSection title="">
                    <AccountItem name="Id" value={userInfo?.tenant?.user?.id} />
                    <AccountItem name="Name" value={userInfo?.tenant?.user?.name} />
                    <AccountItem name="Color" value={userInfo?.tenant?.user?.color} color={userInfo?.tenant?.user?.color} />
                    <AccountItem name="Time Format" value={userInfo?.tenant?.user?.timeFormat} />
                    <AccountItem name="Time Zone" value={userInfo?.tenant?.user?.timezone} />
                    <AccountItem name="Date Format" value={userInfo?.tenant?.user?.dateFormat} />
                    <AccountItem name="Email Notifications Start Time" value={userInfo?.tenant?.user?.emailNotificationsStartTime} />
                    <AccountItem name="Email Notifications End Time" value={userInfo?.tenant?.user?.emailNotificationsEndTime} />
                    <AccountItem name="Email Notifications" value={userInfo?.tenant?.user?.emailNotifications} />
                    <AccountItem name="Email Mentions" value={userInfo?.tenant?.user?.emailMentions} />
                    <AccountItem name="Email Task Updates" value={userInfo?.tenant?.user?.emailTaskUpdates} />
                    <AccountItem name="In App Mentions" value={userInfo?.tenant?.user?.inAppMentions} />
                    <AccountItem name="In App Notifications" value={userInfo?.tenant?.user?.inAppNotifications} />
                    <AccountItem name="In App Task Updates" value={userInfo?.tenant?.user?.inAppTaskUpdates} />
                </AccountSection>
            }
        </div>
    </div>
    );
}

export default App;
